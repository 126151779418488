.App {
  text-align: center;
  background-color: rgb(206, 213, 223);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
  padding-left: 30px;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 30px;
  margin-top: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CardContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Card {
  position: relative;
  background-color: white;
  padding: 32px;
  display: flex;
  width: 500px;
  max-width: 90vw;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
  0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  margin-bottom: 30px
}

.ReferenceUpload {
  position: absolute;
  right: 34px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  margin-bottom: 20px;
}

.CurrentReference {
  position: absolute;
  left: 34px;
  font-size: 16px;
  margin-bottom: 20px;
}

.ProcessingResult {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ProcessingResult a {
  margin-left: 10px;
  margin-right: 10px;
}

.CardCloseButton {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.Footer {
  display: flex;
  padding: 10px 5px 25px;
  font-size: calc(0.6em + 0vh);
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.result {
  position: relative;
}
