.main {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    margin-bottom: 30px;
    width: 500px;
    position: relative;
    backgroundColor: "white";
}

.progress {
    position: relative;
    background-color: darkred;
    top: 40px;
}

.controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    bottom: 0
}

.audio
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


a {
    font-size: 0.4em;
    padding-bottom: 10px;
    padding-right: 10px;
}

.annotation {
    position: absolute;
    height: 4px;
    opacity: 0.5;
}
